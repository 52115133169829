const ShopSettingsPage = () => import('@/pages/shop-settings/ShopSettingsPage')
const SitePagesPage = () => import('@/pages/site-pages/SitePagesPage')
const QuickStartPage = () => import('@/pages/quick-start/QuickStartPage')
const ManageAccessPage = () => import('@/pages/manage-access/ManageAccessPage')

export default [
	{ path: '/shop-settings', name: 'shopSettingsPage', component: ShopSettingsPage },
	{ path: '/shop-settings/:bypass', name: 'shopSettingsPage', component: ShopSettingsPage, props: true },
  { path: '/site-pages', name: 'sitePagesPage', component: SitePagesPage },
	{ path: '/quick-start', name: 'quickStartPage', component: QuickStartPage },
	{ path: '/manage-access', name: 'manageAccessPage', component: ManageAccessPage }
]
