let _listeners = []
let _handleMessage = (event) => {
	_listeners.forEach(listener => {
		if (listener.eventName === event.data.name) {
			listener.callback(event.data.payload)
		}
	})
	_listeners = _listeners.filter(listener => listener.eventName !== event.data.name)

}

const messagesModule = {
	init() {
		if (window.addEventListener) {
			window.addEventListener("message", _handleMessage, false);
		} else {
			window.attachEvent("onmessage", _handleMessage);
		}
	},
  addListener(eventName, callback) {
		_listeners.push({
			eventName: eventName,
			callback: callback
		})
	}
}

Object.freeze(messagesModule)
export default messagesModule