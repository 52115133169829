import Vue from 'vue'
import Vuex from 'vuex'
import ServicesStore from '@narrative.io/tackle-box/src/modules/app/store/servicesStore'

const manifestKey = "nio:shop-builder-manifest"

Vue.use(Vuex)

let store = new Vuex.Store({
	state: {
		siteManifest: null
	},
	getters: {
		siteManifest: (state) => {
			return state.siteManifest
		}
	},
	mutations: {
		['SET_SITE_MANIFEST'] (state, manifest) {
			state.siteManifest = manifest
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_GLOBAL_COLORS'] (state, colors) {
			state.siteManifest.global.colors = colors
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_DOMAIN_PROPERTIES'] (state, props) {
			state.siteManifest.url = props.domain
			if (!state.siteManifest.config) {
				state.siteManifest.config = {}
			}
			state.siteManifest.config.ga = props.ga
			state.siteManifest.config.gtm = props.gtm
			state.siteManifest.config.shopName = props.shopName
			state.siteManifest.config.description = props.description
			state.siteManifest.config.tags = props.tags
			state.siteManifest.config.featuredSearchTerms = props.featuredSearchTerms
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_HOME_PAGE_PROPERTIES'] (state, props) {
			state.siteManifest.pages.home = {
				heading: props.heading,
				subheading: props.subheading,
				hero: {
					headline: {
						text: props.heroHeadlineText,
						color: props.heroHeadlineColor
					},
					tagline: {
						text: props.heroTaglineText,
						color: props.heroTaglineColor
					},
					backgroundColor: props.heroBackgroundColor,
					backgroundImage: null
				}
			}
			if (props.pageBackground) {
				state.siteManifest.pages.home.backgroundColor = props.pageBackground
			}
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_PRODUCT_DETAIL_PAGE_PROPERTIES'] (state, props) {
			state.siteManifest.pages.productDetail = {
				bannerBackground: props.bannerBackground
			}
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_LOGIN_REGISTER_PROPERTIES'] (state, props) {
			state.siteManifest.pages.loginRegister = {
				backgroundColor: props.backgroundColor,
				backgroundTextColor: props.backgroundTextColor
			}
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_MENU_PROPERTIES'] (state, props) {
			state.siteManifest.navbar = {
				backgroundColor: props.background,
				linkColor: props.linkColor,
				linkText: {
					home: props.homeLinkText
				},
				icons: {
					color: props.icon,
					activeColor: props.iconActive
				}
			}
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_DISPLAY_PROPERTIES'] (state, props) {
			state.siteManifest.global.logo = props.logo
			state.siteManifest.global.logoAlt = props.logoAlt
			state.siteManifest.global.placeholderImage = props.placeholderImage
			if (!state.siteManifest.config) {
				state.siteManifest.config = {}
			}
			state.siteManifest.config.favicon = props.favicon
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_VISIBILITY_PROPERTIES'] (state, props) {
			if (!state.siteManifest.config) {
				state.siteManifest.config = {}
			}
			state.siteManifest.config.listOnDsmn = props.listOnDsmn
			state.siteManifest.authRequired = props.authRequired

			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		},
		['SET_SUPPORT_PROPERTIES'] (state, props) {
			if (!state.siteManifest.config) {
				state.siteManifest.config = {}
			}
			state.siteManifest.supportEmail = props.supportEmail
			localStorage.setItem(manifestKey, JSON.stringify(state.siteManifest))
		}
	},
  actions: {
		setSiteManifest({ commit }, manifest) {
			commit('SET_SITE_MANIFEST', manifest)
		},
		setGlobalColors({ commit }, colors) {
			commit('SET_GLOBAL_COLORS', colors)
		},
		setDomainProperties({ commit }, props) {
			commit('SET_DOMAIN_PROPERTIES', props)
		},
		setHomePageProperties({ commit }, props) {
			commit('SET_HOME_PAGE_PROPERTIES', props)
		},
		setProductDetailPageProperties({ commit }, props) {
			commit('SET_PRODUCT_DETAIL_PAGE_PROPERTIES', props)
		},
		setLoginRegisterProperties({ commit }, props) {
			commit('SET_LOGIN_REGISTER_PROPERTIES', props)
		},
		setMenuProperties({ commit }, props) {
			commit('SET_MENU_PROPERTIES', props)
		},
		setDisplayProperties({ commit }, props) {
			commit('SET_DISPLAY_PROPERTIES', props)
		},
		setVisibilityProperties({ commit }, props) {
			commit('SET_VISIBILITY_PROPERTIES', props)
		},
		setSupportProperties({ commit }, props) {
			commit('SET_SUPPORT_PROPERTIES', props)
		}
	},	
	modules: {
		nioServices: ServicesStore
	}
})

export default store