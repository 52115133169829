<template lang="pug">
  v-app(ref="app")
    v-main
      router-view
</template>

<script>

import Vue from 'vue'
import messagesModule from '@/modules/messages'

export default { 
  name: 'App',
  mounted() {
    messagesModule.init()
		this.nioInitializeApplication(this)
		console.log("Version", process.env.APP_VERSION)
    console.log("Tacklebox Version", process.env.TACKLEBOX_VERSION)
    console.log("Commit", process.env.APP_GIT_HASH)
  }
}
</script>

<style lang="sass">

@import "@narrative.io/tackle-box/src/styles/global/_fonts"
@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/global/_color-helpers"
@import "@narrative.io/tackle-box/src/styles/global/_typography"
@import "@narrative.io/tackle-box/src/styles/global/_breakpoints"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

// global
.v-overlay.v-overlay--active.theme--dark .v-overlay__scrim
  background-color: $c-canvas-darker !important
  opacity: 0.9 !important
  backdrop-filter: blur(3px)
  box-shadow: none
  
.app-header
  display: flex
  justify-content: center
  align-items: flex-start

.app-dialog
  background-color: $c-white
  max-height: unset !important
  width: calc(100vw - 64px)
  padding: 24px
  border: 1px solid $c-primary-lighter !important
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important
  border-radius: 12px !important
  
.cancel-dialog
  width: 650px !important
  padding: 0px !important
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important
  border-radius: 12px !important

.app-loading
  width: 100%
  height: 100%
  position: relative
  .v-progress-circular
    position: fixed
    left: 50%
    top: 300px
    margin-left: -2.5rem
    z-index: 2

.nio-step-content
  position: relative

.step-loading
  width: 100%
  height: 100%
  position: relative
  .v-progress-circular
    position: absolute
    left: 50%
    top: -15px
    margin-left: -2.5rem
    z-index: 2	

.step-header
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 8px
  margin-bottom: 24px
  p
    margin-top: 8px

#app 
  font-family: 'Euclid Circular A'
  .v-main__wrap

  
.shop-settings-page, .site-pages-page
  .nio-color-picker
    width: 100%
  & .filter + .filter
    border-top: 1px solid $c-primary-lighter
    border-top-left-radius: 0px
    border-top-right-radius: 0px
  .filter
    +nio-filter-header
    background-color: $c-white
    .filter-value
      width: 518px
      .description
        max-width: 360px
  .sub-container
    border: 1px solid $c-primary-lighter
    border-radius: 12px
    overflow: hidden
  & .sub-container + .sub-container
    margin-top: 24px



</style>
